@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900");

body {
  font-size: 12px;
  font-family: "Roboto", sans-serif;
  background: #fafafa;
  color: #555;
}
a:hover {
  text-decoration: none;
}
button:focus {
  outline: none;
}
table th,
table td {
  border-bottom: 1px solid #ededed;
}
table tr:last-child td {
  border-bottom: none;
}
button {
  border-radius: 4px !important;
  box-shadow: unset !important;
}

/* ------------------------------------------- */

.absolute {
  position: absolute;
}
.no-margin {
  margin: 0 !important;
}
.no-padding {
  padding: 0 !important;
}

/* ------------------------------------------- */

.list .col,
.list .col-2,
.list .col-12 {
  padding-left: 30px;
}
.list .row .row .col {
  padding-left: 15px;
}
.list .row {
  padding: 16px 0;
  cursor: pointer;
  border-bottom: 1px solid #ededed;
}
.list .row:last-child {
  border-bottom: none;
}
.list .row .row {
  padding: 0;
  border-bottom: none;
}
.list .row:hover {
  background-color: rgba(33, 150, 243, 0.08);
}
.list.not-hover .row:hover {
  background-color: unset;
}
.list.not-hover .row {
  cursor: default;
}
.list .row .row:hover {
  background-color: unset;
}
.list .row.disabled:hover {
  background-color: transparent;
  cursor: default;
}

/* ------------------------------------------- */

.title {
  min-height: 30px;
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 20px;
  color: #000;
  font-weight: bold;
  text-transform: uppercase;
}
.subtitle {
  padding-top: 4px;
}
.section {
  padding: 60px 0 80px 0;
}
.section.min {
  padding: 20px 0 40px 0;
}
.section-title {
  margin-top: 20px;
}
.card {
  background: white;
  border: unset;
  border-radius: unset;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.05),
    0px 6px 40px 0px rgba(0, 0, 0, 0.03), 0px 1px 18px 0px rgba(0, 0, 0, 0.02);
}
.card.rounded {
  border-radius: 16px !important;
}
.form-control {
  display: block !important;
  border: unset !important;
  padding: unset !important;
}
.form-control label {
  text-transform: initial;
}
.form-control.select label {
  font-size: 12px;
  margin-bottom: 0;
}
.form-control.select label,
.form-control.select .select {
  width: 100%;
  transform: translateY(-8px);
  text-align: left;
}
.form-control-error {
  text-align: left;
  padding: 16px 0 0 4px;
  font-size: 10px;
  /* margin-bottom: 14px; */
  min-height: 31px;
  color: #f34f45;
  display: block;
}
.modal-dialog {
  transform: translateY(40px) !important;
}
.modal-top-title {
  position: absolute;
  transform: translateY(-26px);
  color: white;
}
.modal-top-close {
  position: absolute !important;
  right: 0;
  transform: translate(28px, -28px);
  border-radius: 38px !important;
}
.modal-top-close i {
  font-size: 14px;
  color: white;
}
.modal-title {
  width: 100%;
}
.modal-content {
  border-radius: 0;
  border: none;
  background: transparent;
}
.modal-body {
  padding: 30px 20px 20px 20px;
  font-size: 14px;
}
.modal-footer {
  display: block;
}
.modal-footer-left {
  float: left;
}
.modal-footer-right {
  float: right;
}
.modal-body.footer-error {
  padding-bottom: 10px;
}
.modal-backdrop.show {
  opacity: 0.8;
}
.order-state {
  display: block;
  text-align: center;
  font-size: 16px;
  padding: 10px 20px;
  margin: 20px 10px;
  border-radius: 2px;
}
.order-state.success {
  background-color: #d4edda;
  border-color: #c3e6cb;
  color: #155724;
}
.order-state.warning {
  background-color: #fff3cd;
  border-color: #ffeeba;
  color: #856404;
}
.order-state.dark {
  background-color: #d6d8d9;
  border-color: #c6c8ca;
  color: #1b1e21;
}
.order-state.info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}
.order-state.min {
  display: inline-block;
  margin: 0;
  padding: 4px 10px;
  font-size: 12px;
}

/* ------------------------------------------- */

.user-page .container {
  max-width: unset;
}
.user-page .user-page-top button {
  width: 40px;
  height: 40px;
  margin-left: 4px;
  border-radius: 40px !important;
}
.user-page .user-page-top i {
  font-size: 20px;
  color: white;
  transform: translateY(-2px);
}
.user-page-background {
  position: absolute;
  width: 100vw;
  height: 45vh;
}
.user-page-content {
  position: relative;
  margin: auto;
  max-width: 100%;
  color: white;
}
.user-page-register,
.user-page-profile,
.user-page-activation {
  background: linear-gradient(0deg, transparent 50%, #4caf50 50%);
}
.user-page .card,
.contact-page .card {
  margin: 20px 0px;
  padding: 60px 30px 40px 30px;
  background: white;
  color: #555;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.1),
    0px 6px 40px 0px rgba(0, 0, 0, 0.06), 0px 1px 18px 0px rgba(0, 0, 0, 0.04);
}
.user-page-profile .card {
  padding: 0;
}
.user-page .card button {
  position: absolute;
  right: 30px;
  bottom: 30px;
}
.user-page .user-links {
  margin: 0px 10px;
  font-size: 14px;
  line-height: 28px;
}
.user-page form {
  margin-bottom: 60px;
}
.user-page .result,
.cart-page .result {
  font-size: 22px;
  color: #555;
}
.user-page .result i,
.cart-page .result i {
  font-size: 40px;
  color: #8bc34a;
}

/* ------------------------------------------- */

.tag {
  display: inline-block;
  padding: 2px 4px;
  border-radius: 2px;
  top: 8px;
  right: 8px;
  font-size: 10px;
  text-transform: uppercase;
}
.tag.inactive,
.tag.out-of-stock  {
  background: #f44336;
  color: white;
}
.tag.original {
  background: #2196f3;
  color: white;
}

/* ------------------------------------------- */

.product-admin-actions:not(.absolute) {
  width: 100%;
  padding-bottom: 40px;
}
.product-admin-actions {
  padding: 2px;
}
.product-admin-actions button {
  padding: 0;
  font-size: 10px;
  border: none;
  background: none;
  cursor: pointer;
  color: #555;
  text-transform: uppercase;
}
.product-admin-actions button i {
  transform: translateY(-1px);
  margin-left: 8px;
  margin-bottom: 2px;
  font-size: 8px;
}

/* ------------------------------------------- */

.scroll {
  height: 100%;
  overflow-y: auto;
}
.scroll::-webkit-scrollbar {
  width: 14px;
  background: transparent;
}
.scroll::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-clip: padding-box;
  border: 4px solid transparent;
  background-color: rgba(0, 0, 0, 0.15);
}
.img-item {
  width: 100px;
  height: 100px;
  margin-right: 16px !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: rgba(0, 0, 0, 0.03) !important;
  /* cursor: pointer; */
  border-radius: 0 !important;
  border: none;
  color: grey;
}
.img-item.new,
.img-item:hover {
  background-color: rgba(0, 0, 0, 0.06) !important;
}

/* ------------------------------------------- */
/* react-id-swiper */

button.swiper-button-prev,
button.swiper-button-next {
  background-color: transparent;
  border: none;
}
.swiper-pagination-bullet-active {
  background: gray;
}

/* ------------------------------------------- */

.object-section {
  font-size: 12px;
  color: #555;
}
.object-section .container {
  /* padding-right: 0; */
}
.object-section.p {
  padding: 20px 0 0 0;
}
.object-section .container {
  max-width: unset !important;
}
.object-section .row .col {
  padding: 0;
}
.object-section .row {
  margin-right: -15px;
  margin-left: -15px;
  padding: 0;
  border-bottom: 1px solid #ededed;
}
.object-section .row .col-auto > *,
.object-section .row .col > *,
.object-section .row .col-3 > * {
  display: inline-block;
  padding: 22px 0 22px 24px;
}
.object-section label {
  margin-bottom: 0;
  font-size: 10px;
  padding-top: 24px !important;
  opacity: 0.8;
  color: #555;
  text-transform: uppercase;
}
.object-section .row:last-child {
  border-bottom: none;
}
.object-section .title {
  font-size: 14px;
  padding: 0 0 10px 24px;
  margin: 0;
  display: block;
  color: #555;
}
.object-section i {
  font-size: 11px;
  margin-right: 4px;
}
.object-section a {
  color: #555;
}
.object-section a:hover {
  color: black;
}
.object-section-value {
  margin: 0;
}
.object-section-default {
  font-size: 10px;
  color: #757575;
  border: solid 1px #bdbdbd;
  margin-top: 22px;
  margin-right: 20px;
  float: right;
  padding: 2px 4px;
  text-transform: uppercase;
}

/* ------------------------------------------- */

/*Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) {
  .user-page .card,
  .user-page .user-links {
    margin: 20px;
  }
  .object-section.p {
    padding: 30px 0 0 0;
  }
  .object-section label {
    font-size: 12px;
  }
}

/*Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
}

/*Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {
  .object-section {
    font-size: 14px;
  }
}

@media (min-width: 1025px) {
}

/* Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
}
